import { GetterTree } from 'vuex'
import { GameStatus, GridCell, GridCellContainer, GridCellDataContainer, VSweeperState } from './types'

export const getters: GetterTree<VSweeperState, VSweeperState> = {
  getCellsToSearchForMine: (state) => (cell: GridCell) => {
    const keyFromNum = (num: number) => GridCell.prototype.key.call({ num })
    const numsOfCellsToSearch = []
    const numOfUpperRow = cell.num - cell.level.cols
    const numOfLowerRow = cell.num + cell.level.cols
    if (cell.haveLeftCol) numsOfCellsToSearch.push(cell.num - 1)
    if (cell.haveRightCol) numsOfCellsToSearch.push(cell.num + 1)

    if (cell.haveUpperRow) numsOfCellsToSearch.push(numOfUpperRow)
    if (cell.haveUpperRow && cell.haveLeftCol) numsOfCellsToSearch.push(numOfUpperRow - 1)
    if (cell.haveUpperRow && cell.haveRightCol) numsOfCellsToSearch.push(numOfUpperRow + 1)

    if (cell.haveLowerRow) numsOfCellsToSearch.push(numOfLowerRow)
    if (cell.haveLowerRow && cell.haveLeftCol) numsOfCellsToSearch.push(numOfLowerRow - 1)
    if (cell.haveLowerRow && cell.haveRightCol) numsOfCellsToSearch.push(numOfLowerRow + 1)

    return numsOfCellsToSearch.map(num => state.cells[keyFromNum(num)])
  },
  cellsNumber: (state) => {
    return Object.keys(state.cells).length
  },
  selectedCellsNumber: (state) => {
    return Object.keys(state.selected).length
  },
  flaggedCellsNumber: (state) => {
    return Object.keys(state.flagged).length
  },
  bombedCellsNumber: (state) => {
    return Object.keys(state.bombs).length
  },
  correctlyFlaggedCells: (state) => {
    const cflag = []
    for (const cellKey of Object.keys(state.flagged)) {
      if (state.bombs[cellKey]) cflag.push(state.flagged[cellKey])
    }
    return cflag
  },
  cellData: (state) => {
    const data: GridCellDataContainer = {}
    for (const cellKey of Object.keys(state.cells)) {
      data[cellKey] = {
        cell: state.cells[cellKey],
        get isBombed () { return !!state.bombs[this.cell.key()] },
        get isExploded () { return this.cell.key() === state.explodedBomb },
        get isFlagged () { return !!state.flagged[this.cell.key()] },
        get isSelected () { return !!state.selected[this.cell.key()] || state.status > GameStatus.PLAYING },
        get isColEven () { return this.cell.col % 2 === 0 },
        get isRowEven () { return this.cell.row % 2 === 0 },
        get bombs () { return state.bombsNearCell[this.cell.key()] || 0 }
      }
    }
    return data
  },
  currentLevel: (state) => state.levels[state.currentLevel]
}
