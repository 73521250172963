import { ActionTree } from 'vuex'
import { GameLevel, GameStatus, GridCell, VSweeperState } from './types'

export const actions: ActionTree<VSweeperState, VSweeperState> = {
  detectNearMine: ({ commit, dispatch, state, getters }, { cell, iteration = 1 }) => {
    const cellKey = cell.key()
    if (cell && cell instanceof GridCell && !state.flagged[cellKey] && !state.bombsNearCell[cellKey] && !state.selected[cellKey]) {
      const cellsToCheck = getters.getCellsToSearchForMine(cell)
      let cellsWithBomb = 0
      const cellsClean = []
      console.log('iteration:', iteration)
      for (const curCell of cellsToCheck) {
        if (state.bombs[curCell.key()]) {
          cellsWithBomb++
        } else {
          cellsClean.push(curCell)
        }
      }

      if (!state.selected[cellKey]) {
        commit('SELECT_CELL', cell)
        commit('INCREASE_SCORE')
      }

      if (cellsWithBomb > 0) {
        commit('SET_BOMBS_NEAR_CELL', { key: cellKey, bombs: cellsWithBomb })
      } else if (iteration < 4) {
        cellsClean.forEach(
          c =>
            setTimeout(() => dispatch('detectNearMine', { cell: c, iteration }), 0)
        )
      }
    }
  },
  onCellPrimaryClick: ({ state, commit, dispatch, getters }, cell: GridCell) => {
    if (state.status > GameStatus.PLAYING) return
    const cellKey = cell.key()

    if (state.status < GameStatus.PLAYING) {
      commit('GENERATE_BOMBS', cell)
      commit('SET_STATUS', GameStatus.PLAYING)
    }

    if (state.selected[cellKey] || state.flagged[cellKey]) return

    if (state.bombs[cellKey]) {
      commit('SET_STATUS', GameStatus.EXPLODED)
      commit('SET_EXPLODED_BOMB', cellKey)
      dispatch('onGameEnd')
    } else {
      dispatch('detectNearMine', { cell })
      if (getters.selectedCellsNumber + getters.flaggedCellsNumber === getters.cellsNumber) {
        commit('SET_STATUS', GameStatus.WINNED)
        dispatch('onGameEnd')
      }
    }
  },
  onGameEnd: ({ state }) => {
    state.counters.game?.stop()
    state.showPopups.game = true
  },
  onCellSecondaryClick: ({ state, commit, dispatch, getters }, cell: GridCell) => {
    const cellKey = cell.key()
    if (state.selected[cellKey] || state.status > GameStatus.PLAYING) return

    if (state.flagged[cellKey]) {
      commit('UNFLAG_CELL', cell)
    } else {
      if (getters.flaggedCellsNumber >= cell.level.bombs) return
      commit('FLAG_CELL', cell)
      if (getters.selectedCellsNumber + getters.flaggedCellsNumber === getters.cellsNumber) {
        commit('SET_STATUS', GameStatus.WINNED)
        dispatch('onGameEnd')
      }
    }
  },
  start: async ({ state, commit }, level: GameLevel) => {
    if (typeof level === 'undefined') level = state.levels[state.currentLevel]
    commit('SET_LOADING_GRID', true)
    commit('SET_STATUS', GameStatus.STARTING)
    state.counters.game?.reset()
    setTimeout(() => {
      commit('CLEAR_GAME')
      commit('SET_STATUS', GameStatus.STARTING)
      for (let i = 0; i < level.cells; i++) {
        commit('ADD_CELL', new GridCell(level, i + 1))
      }
      state.counters.game?.start()
      commit('SET_LOADING_GRID', false)
    }, 50)
  },
  stop: async ({ commit }) => {
    commit('CLEAR_GAME')
    commit('SET_STATUS', GameStatus.IDLE)
    commit('SET_LOADING_GRID', false)
  }
}
