import { createStore } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { GameStatus, GameLevel, VSweeperState, GridSize } from './types'

export const defaultState: () => VSweeperState = () => ({
  levels: [
    new GameLevel('Facile', 9, 9, 10, GridSize.LARGE),
    new GameLevel('Normale', 16, 16, 40, GridSize.LARGE),
    new GameLevel('Medio', 25, 25, 65, GridSize.MEDIUM),
    new GameLevel('Difficile', 30, 16, 99, GridSize.SMALL),
    new GameLevel('Demone', 20, 36, 220, GridSize.SMALL)
  ],
  currentLevel: parseInt(localStorage.getItem('store.currentLevel') || '0'),
  status: GameStatus.IDLE,
  score: 0,
  gameTimer: '',
  loadingGrid: false,
  cells: {},
  selected: {},
  flagged: {},
  bombs: {},
  explodedBomb: '',
  bombsNearCell: {},
  counters: {},
  showPopups: { info: localStorage.getItem('info-visited') !== 'true', game: false }
})

export default createStore<VSweeperState>({
  state: defaultState(),
  getters,
  mutations,
  actions
})
