import { MutationTree } from 'vuex'
import { defaultState } from '.'
import { rangedRandomNumber } from './helper'
import { CounterInterface, CounterOptions, GameStatus, GridCell, ShowEmptyTime, VSweeperState } from './types'
const SCORE_INCREMENTER = 5
function generateTimeText (opt: {counter: number, showEmpty: ShowEmptyTime}) {
  let time = ''
  const seconds = opt.counter % 60
  const m = opt.counter / 60
  const minutes = Math.floor(m) % 60
  const h = m / 60
  const hours = Math.floor(h) % 24
  const d = h / 24
  const days = Math.floor(d)
  if (days > 0 || opt.showEmpty.days) time += ('' + days).padStart(2, '0') + ' '
  if (hours > 0 || opt.showEmpty.hours) time += ('' + hours).padStart(2, '0') + ':'
  if (minutes > 0 || opt.showEmpty.minutes) time += ('' + minutes).padStart(2, '0') + ':'
  if (seconds > 0 || opt.showEmpty.seconds) time += ('' + seconds).padStart(2, '0')
  return time
}
export const mutations: MutationTree<VSweeperState> = {
  RESET_STORE: function (state) {
    const defState = defaultState()
    for (const key in defState) {
      (state as unknown as {[key:string]:unknown})[key] = (defState as unknown as {[key:string]:unknown})[key]
    }
  },
  SET_LOADING_GRID: function (state, val: boolean) {
    state.loadingGrid = val
  },
  SET_SHOW_POPUP: function (state, obj: { key: string, value: boolean}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state.showPopups as any)[obj.key] = obj.value
  },
  SET_CURRENT_LEVEL: function (state, level: number) {
    state.currentLevel = level
    localStorage.setItem('store.currentLevel', '' + level)
  },
  SET_STATUS: function (state, status: GameStatus) {
    state.status = status
  },
  ADD_CELL (state, cell: GridCell) {
    state.cells[cell.key()] = cell
  },
  CLEAR_CELLS (state) {
    state.cells = {}
  },
  SELECT_CELL (state, cell: GridCell) {
    state.selected[cell.key()] = cell
  },
  INCREASE_SCORE (state) {
    state.score += SCORE_INCREMENTER
  },
  FLAG_CELL (state, cell: GridCell) {
    state.flagged[cell.key()] = cell
  },
  UNFLAG_CELL (state, cell: GridCell) {
    delete state.flagged[cell.key()]
  },
  SET_EXPLODED_BOMB (state, cellKey: string) {
    state.explodedBomb = cellKey
  },
  GENERATE_BOMBS (state, initialCell: GridCell) {
    const curLvl = state.levels[state.currentLevel]
    let i = 0
    while (i < curLvl.bombs) {
      const rCell = state.cells[GridCell.prototype.key.call({ num: rangedRandomNumber(1, curLvl.cells) })]
      if (rCell.num !== initialCell.num && !state.bombs[rCell.key()]) {
        state.bombs[rCell.key()] = rCell
        i++
      }
    }
  },
  SET_BOMBS_NEAR_CELL (state, cell: { key: string, bombs: number }) {
    state.bombsNearCell[cell.key] = cell.bombs
  },
  CLEAR_GAME (state) {
    const defState = defaultState()
    state.status = defState.status
    state.score = defState.score
    state.cells = defState.cells
    state.selected = defState.selected
    state.flagged = defState.flagged
    state.bombs = defState.bombs
    state.bombsNearCell = defState.bombsNearCell
  },
  GENERATE_COUNTER (state, options: CounterOptions<VSweeperState>) {
    let seconds = 0
    let inter = -1
    const counter: CounterInterface<VSweeperState> = {
      ...options,
      text: () => generateTimeText({ counter: seconds, showEmpty: options.showEmpty }),
      start: () => {
        if (inter >= 0) return
        inter = setInterval(() => {
          seconds++
          (state[options.counterProperty] as unknown as string) = counter.text()
        }, 1000)
      },
      stop: () => {
        if (inter < 0) return
        clearInterval(inter)
        inter = -1;
        (state[options.counterProperty] as unknown as string) = ''
      },
      reset: () => {
        counter.stop()
        seconds = 0
      }
    }
    state.counters[options.name] = counter
  }

}
