export enum GameStatus {IDLE, STARTING, PLAYING, EXPLODED, WINNED}
export enum GridSize {SMALL = 'small', MEDIUM = 'medium', LARGE = 'large'}

export interface IGameLevel {
  name: string
  cols: number
  rows: number
  bombs: number
  size: GridSize
  get key (): string
  get description (): string
  get cells(): number
}

export class GameLevel implements IGameLevel {
  name: string
  cols: number
  rows: number
  bombs: number
  size: GridSize

  constructor (name: string, cols: number, rows: number, bombs: number, size: GridSize) {
    this.name = name
    this.cols = cols
    this.rows = rows
    this.bombs = bombs
    this.size = size
  }

  get key (): string {
    return `${this.cols}x${this.rows}`
  }

  get description (): string {
    return `${this.name} (${this.key}) (${this.bombs} Mine)`
  }

  get cells (): number {
    return this.cols * this.rows
  }
}
export class GridCell {
  level: GameLevel
  num: number
  constructor (level: GameLevel, num: number) {
    this.level = level
    this.num = num
  }

  key (): string {
    return `cell-${this.num}`
  }

  get col (): number {
    return ((this.num - 1) % this.level.cols) + 1
  }

  get row (): number {
    return Math.ceil(this.num / this.level.cols)
  }

  get haveUpperRow (): boolean {
    return this.row > 1
  }

  get haveLowerRow (): boolean {
    return this.row < this.level.rows
  }

  get haveLeftCol (): boolean {
    return this.col > 1
  }

  get haveRightCol (): boolean {
    return this.col < this.level.cols
  }
}
export interface GridCellData {
  cell: GridCell
  get isExploded(): boolean
  get isBombed(): boolean
  get isFlagged(): boolean
  get isSelected (): boolean
  get isColEven (): boolean
  get isRowEven (): boolean
  get bombs(): number
}

export interface ShowEmptyTime {
  seconds: boolean
  minutes: boolean
  hours: boolean
  days: boolean
}

export interface CounterOptions<T>{
  name: string
  counterProperty: keyof T
  showEmpty: ShowEmptyTime
}
export interface CounterInterface<T> extends CounterOptions<T>{
  start: () => void
  stop: () => void
  reset: () => void
  text: () => string
}

export type GridCellContainer = { [key: string]: GridCell }
export type GridCellKeyContainer = { [key: string]: number}
export type GridCellDataContainer = { [key: string]: GridCellData }
export type CountersContainer<T> = { [key: string]: CounterInterface<T> | undefined}

export interface VSweeperState {
  levels: GameLevel[]
  currentLevel: number,
  status: GameStatus
  score: number
  cells: GridCellContainer
  selected: GridCellContainer
  flagged: GridCellContainer
  bombs: GridCellContainer
  explodedBomb: string
  bombsNearCell: GridCellKeyContainer
  counters: CountersContainer<this>
  gameTimer: string,
  loadingGrid: boolean,
  showPopups: {info: boolean, game: boolean}
}
